/* Built In imports */
import NextLink from 'next/link';
/* External Imports */
import {
  Box,
  Flex,
  Hide,
  Image,
  Link,
  List,
  ListItem,
  Text,
  chakra,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { IoIosArrowRoundUp } from 'react-icons/io';
/* Internal Imports */
import StructuredTextParser from '@shi/Utility/StructuredTextParser';
import { refineUrlDomain } from '@shi/components/Utility/utils';
import FooterSt from '@shi/styleData/FooterSt';

/* Components */
/* Styles */
/**
 * Renders Footer Component
 * @param {Obj} footerData
 * @param {Str} region
 * @param {Str} language
 * @returns {ReactElement} Footer
 */

const Footer = ({ region, language, footerData }) => {
  // console.log(footerData, 'footerData');
  const footerSubItems = footerData?.allIsoMainMenus[0].submenuItems;
  // console.log('mydata', footerSubItems);
  const contact = footerSubItems.find(el => el.key === 'contact');
  const location = footerSubItems.find(el => el.key === 'location');
  const tNCon = footerSubItems.find(el => el.key === 'terms-and-conditions');
  const privacy = footerSubItems.find(el => el.key === 'privacy');
  const copyright = footerSubItems.find(el => el.key === 'copyright');
  const social_media = footerSubItems.find(el => el.key === 'social_media');

  const flexStyle = {
    flexDirection: { base: 'column', lg: 'row' },
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  };
  const Arrow = chakra(IoIosArrowRoundUp);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Box
      as="footer"
      bg="#141414"
      width="100%"
      py={{ base: '40px', md: '60px' }}
      px="35px"
      id="footer"
    >
      <Box maxW="1100px" width="100%" mx="auto">
        <Flex {...flexStyle}>
          <Box>
            {' '}
            <List display="flex" flexDir="column">
              {footerSubItems?.map((menu, index) => {
                if (index < 6) {
                  return (
                    <ListItem>
                      <NextLink
                        href={refineUrlDomain(menu.url, region, language)}
                      >
                        <Link
                          {...FooterSt[language].footerLink}
                          _focus={{ boxShadow: 'none' }}
                          width="auto"
                        >
                          {menu?.title}
                        </Link>
                      </NextLink>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Box>
          <Box>
            <Text
              {...FooterSt[language].footerTitle}
              mt={{ base: '35px', xl: '0' }}
            >
              {contact.title}
            </Text>
            <StructuredTextParser
              {...FooterSt[language].footerDes}
              str={render(contact?.body)}
              id="shiFooterDes"
              region={region}
              lang={language}
              px="0"
            />
          </Box>
          <Box>
            <Text
              {...FooterSt[language].footerTitle}
              mt={{ base: '35px', xl: '0' }}
            >
              {location.title}
            </Text>
            <StructuredTextParser
              {...FooterSt[language].footerDes}
              str={render(location?.body)}
              id="shiFooterDes"
              sx={{
                a: {
                  textDecoration: 'underline',
                  fontSize: '12px',
                },
              }}
              px="0"
              lang={language}
              region={region}
            />
          </Box>
          <Box>
            <Text
              {...FooterSt[language].footerTitle}
              mt={{ base: '35px', xl: '0' }}
            >
              {social_media.title}
            </Text>
            <List display="flex" flexDir="row" mt="17px">
              {social_media.submenuItems?.map((menu, index) => {
                if (index < 6) {
                  return (
                    <ListItem>
                      <NextLink
                        href={refineUrlDomain(menu.url, region, language)}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          {...FooterSt[language].footerLink}
                          width="auto"
                          _focus={{ boxShadow: 'none' }}
                          color="#fff"
                        >
                          <Image
                            loading="lazy"
                            width="18px"
                            mr="11px"
                            height="18px"
                            src={menu?.icon?.url}
                            alt={menu?.title}
                            title={menu?.title}
                            _hover={{
                              content: `url(${menu?.iconHover?.url})`,
                            }}
                          />
                        </Link>
                      </NextLink>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Box>
        </Flex>
        <Flex {...flexStyle} mt="30px">
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            width={'100%'}
            w="85%"
            gap="5%"
          >
            <Box>
              <Text>
                <NextLink
                  href={refineUrlDomain(tNCon?.url, region, language)}
                  passHref
                  legacyBehavior
                >
                  <Link
                    {...FooterSt[language]?.footerTitle}
                    fontWeight="400"
                    textDecor="underline"
                    _focus={{ boxShadow: 'none' }}
                    width="auto"
                  >
                    {tNCon?.title}
                  </Link>
                </NextLink>
              </Text>
            </Box>
            <Box mt={{ base: '20px', md: '0' }}>
              <Text>
                {' '}
                <NextLink
                  href={refineUrlDomain(privacy?.url, region, language)}
                  passHref
                  legacyBehavior
                >
                  <Link
                    {...FooterSt[language]?.footerTitle}
                    fontWeight="400"
                    textDecor="underline"
                    _focus={{ boxShadow: 'none' }}
                    width="auto"
                  >
                    {privacy?.title}
                  </Link>
                </NextLink>
              </Text>
            </Box>
            <Box
              mt={{ base: '35px', md: '0' }}
              w={{ base: '260px', md: 'auto' }}
            >
              <Text
                {...FooterSt[language]?.footerTitle}
                fontSize="14px"
                color="#565656"
                fontFamily="FedraSansStd-medium"
                fontWeight="400"
              >
                {' '}
                {/* <NextLink href={refineUrlDomain(copyright?.url, language)}>
                  <Link
                    textDecor="underline"
                    width="auto"
                  > */}
                {copyright?.title}
                {/* </Link>
                </NextLink> */}
              </Text>
            </Box>
          </Flex>
          <Hide below="lg">
            <Flex
              w="15%"
              justifyContent={{ base: 'flex-start', xl: 'flex-end' }}
            >
              <Text
                {...FooterSt[language].scrollTop}
                onClick={scrollToTop}
                cursor="pointer"
              >
                Back to top{' '}
                <Text as="span">
                  <Arrow display="inline" color="#CA4E2A" />
                </Text>
              </Text>
            </Flex>
          </Hide>
        </Flex>
      </Box>
      <style>
        {`
        #shiFooterDes p {
          margin: 0;
          color:#A6A6A6;
        }
        #shiFooterDes p a {
          text-decoration: underline !important;
        }
        #shiFooter p a {
          color: #CA4E2A;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 26.035px;
          text-decoration: underline !important;
        }
        }
          `}
      </style>
    </Box >
  );
};
export default Footer;
