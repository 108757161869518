import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    deskMenu: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: {
        base: '14px',
        md: '16px',
        lg: '16px',
      },
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '15px',
    },
    deskSub: {
      color: '#000',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: {
        base: '12px',
        md: '14px',
        lg: '14px',
      },
      fontStyle: 'normal',
      // fontWeight: '400',
      lineHeight: '16px',
    },
    mobileMenu: {
      color: '#FFF',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      fontSize: '18px',
      fontStyle: 'normal',
      // fontWeight: 700,
      lineHeight: '54px',
    },
    mobileSubmenu: {
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'normal',
      // fontWeight: '600',
      lineHeight: '28px',
      color: '#FFA735',
    },
    mobileSMenu: {
      color: '#FFA735',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-A-medium',
      fontSize: '18px',
      fontStyle: 'normal',
      // fontWeight: 700,
      lineHeight: '54px',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const HeaderSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default HeaderSt;
