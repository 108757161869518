/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import * as sanitizeHtml from 'sanitize-html';

/* Internal Imports */
/* Components */
import htmlParser from '@shi/components/Utility/htmlParser';
import { consoleLog } from '@shi/Utility/Shared/SharedService';

/* Services */

const singularNoAttrTags = [];
const singularAttrTags = ['audio', 'powerpress', 'space'];
const bothTypeTags = ['divider', 'sadhgurusignature', 'image', 'separator'];
const allTags = ['envurl'];

// Function to extract attributes from the given tag in the parser body received from API.
/**
 *
 * @param tagWithAttributes
 */
function getAttributes(tagWithAttributes) {
  let attributesArray = [];

  let attributes = tagWithAttributes.match(/[\w-]+="[^"]*"|[\w-]+=[^\s|\]]*/g);
  if (attributes !== null && attributes.length > 0) {
    attributes.map(att => {
      let separateAttribute = [];
      if (tagWithAttributes.startsWith('[envurl')) {
        separateAttribute = att.split("='");
      } else {
        separateAttribute = att.split('=');
      }
      attributesArray[separateAttribute[0]] = separateAttribute[1].replace(
        /['"]+/g,
        ''
      );
    });
  }
  return attributesArray;
}

/**
 *
 * @param existingLength
 * @param newLength
 */
function getNewIterationBuffer(existingLength, newLength) {
  return newLength - existingLength + 1;
}

/**
 * Renders Short Code Component
 *
 * @param {string} str
 * @returns {ReactElement} Short Code component
 */
export function ShortCodeProcess(str) {
  const router = useRouter();
  const { language, type } = router.query;
  let newCreatedString = str;
  // .replace("'", '')

  try {
    let i = -1;
    // -1 because if the first character starts with tag, we need to take care of that

    let matchShortCodeTag = [];
    // let matchShortCodeTag = newCreatedString.match(/\[([^\d]*?)\/(.*?)]/g);

    // Replace tags which are surrounded by em, strong and b tags by default. We dont need them.
    newCreatedString = newCreatedString.replace(/<em>\[(.*?)<\/em>/g, `[$1`);
    newCreatedString = newCreatedString.replace(
      /<strong>\[(.*?)<\/strong>/g,
      `[$1`
    );
    newCreatedString = newCreatedString.replace(/<b>\[(.*?)<\/b>/g, `[$1`);

    try {
      while (i < newCreatedString.length) {
        let startTagBegin, startTagEnd, endTagEnd;
        do {
          i++;
        } while (i < newCreatedString.length && newCreatedString[i] !== '[');
        startTagBegin = i; // Loop until we find the first bracket of a tag

        do {
          i++;
        } while (i < newCreatedString.length && newCreatedString[i] !== ']');
        startTagEnd = i + 1; // Loop until we find the last bracket of a tag

        // Remove the brackets from the above found tag
        const startTagWithAttributes = newCreatedString?.substring(
          startTagBegin + 1,
          startTagEnd - 1
        );
        // newCreatedString?.substring(startTagBegin + 1, startTagEnd - 1);

        // get the tag with which we have to work with without its attributes
        let startTag = startTagWithAttributes.split(' ')?.[0];

        // Handle case where we might have tags which can be both singular and closing tags
        if (bothTypeTags.includes(startTag.toLowerCase())) {
          if (
            newCreatedString
              ?.substring(startTagEnd)
              .indexOf('[/' + startTag + ']') !== -1
          ) {
            // Do nothing as it has a closing tag further
          } else {
            // Single tag, no closing tag found further down the string
            const attributes = getAttributes(startTagWithAttributes);

            // Special case: Divider tags if singular should not be rendered
            if (startTag === 'divider') {
              startTag = 'singleDivider';
            }

            const html = htmlParser({
              tagWithoutBracket: startTag.toLowerCase(),
              attributesArray: attributes,
              type,
              language,
            });

            // Replace the tag with the tag html received from parser
            newCreatedString =
              newCreatedString.substring(0, startTagBegin) +
              html +
              newCreatedString.substring(startTagEnd);

            // We need to update i value as original string length would change based on our new html length
            const existingLength = startTagEnd - startTagBegin + 1;
            const newLength = html?.length || 0;
            i = i + getNewIterationBuffer(existingLength, newLength);

            // As this is a single tag, we don't need to proceed further to find closing tag.
            // Hence, continue with next iteration finding the new start tag
            continue;
          }
        }
        i++;

        // [1] => Tags like these we need to skip
        if (startTagEnd - startTagBegin === 3) {
          continue;
        }

        while (i < newCreatedString.length) {
          if (newCreatedString[i] === ']' && newCreatedString[i - 2] === '[') {
            // [1] => Tags like these we need to skip
            i++;
            continue;
          } else if (
            // Check if start tag found is singular, we need to ignore such tag
            newCreatedString[i] === '[' &&
            newCreatedString[i + 1] !== '/' && // Check if this is not an ending tag
            newCreatedString[i + 2] !== ']' // [1] => Tags like these we need to skip
          ) {
            i = startTagEnd + 1;
            break;
          } else if (
            // End tag start found
            newCreatedString[i] === '[' &&
            newCreatedString[i + 1] === '/'
          ) {
            // endTagBegin = i;

            // Search from end tag ending.
            do {
              i++;
            } while (
              i < newCreatedString.length &&
              newCreatedString[i] !== ']'
            );
            endTagEnd = i + 1;

            // Push the tags in the array only if they are valid tags
            if (allTags.includes(startTag.toLowerCase())) {
              // Push the tag in the matchShortCodeTag array
              newCreatedString.substring(startTagBegin, endTagEnd) &&
                matchShortCodeTag.push(
                  newCreatedString.substring(startTagBegin, endTagEnd)
                );
              break; // Both start and end tags found, we need to break out of the loop
            } else {
              i = startTagEnd + 1;
              break;
            }
          } else {
            i++;
          }
        }
      }
    } catch (e) {
      consoleLog('Error while processing matchShortCodeTag', e);
    }

    // Above we extracted the entire tags with text
    // Below we extract all tags but without text
    let tags = newCreatedString.match(/\[(.*?)\]/g);

    // Filter tags [1] like these, we dont need them
    tags = tags && tags.filter(tag => tag.length > 3);

    if (tags && matchShortCodeTag !== null) {
      // We need to maintain index for tags with text - we need to replace them with the html content
      let currentMatchShortCodeTagIndex = 0;
      for (let i = 0; i < tags.length; i++) {
        // Filter tags [1] like these, we dont need them, skip the loop
        if (tags[i].length === 3) continue;

        let tagWithoutBracket = '';
        tagWithoutBracket = tags[i].replace('[', '').replace(']', '');
        tagWithoutBracket = tagWithoutBracket.split(' ')[0];
        tagWithoutBracket = tagWithoutBracket.toLowerCase();

        // Filter tags [1] like these, we dont need them
        if (!isNaN(tagWithoutBracket)) {
          continue;
        }
        let text,
          attributesArray = [];

        // Proceed only if the tags are handled by us in parser, else they are invalid tags, ignore them => [one of the eight limbs of yoga]
        if (allTags.includes(tagWithoutBracket)) {
          // Extract attributes from the given tag
          if (!singularNoAttrTags.includes(tagWithoutBracket)) {
            attributesArray = getAttributes(tags[i]);
            if (!singularAttrTags.includes(tagWithoutBracket)) {
              // Extract text between the start and end tags
              text =
                matchShortCodeTag &&
                matchShortCodeTag[currentMatchShortCodeTagIndex]
                  .replace(tags[i], '')
                  .replace(tags[i + 1], '');
            }
          }

          // Get the html code to replace with, with the tag we extracted above

          let htmlCode = htmlParser({
            type,
            tagWithoutBracket,
            text,
            attributesArray,
            language,
          });

          // Remove unclosed tags if any from the data received.
          //If not, it does not render the subsequent tags following it correctly if there is a data issue.
          htmlCode = getSanitizedString(htmlCode);

          // Singular Tags
          if (
            singularNoAttrTags.includes(tagWithoutBracket) ||
            singularAttrTags.includes(tagWithoutBracket)
          ) {
            newCreatedString = newCreatedString.replace(tags[i], htmlCode);
          } else {
            // Closing Tags
            newCreatedString = newCreatedString.replace(
              matchShortCodeTag[currentMatchShortCodeTagIndex],
              htmlCode
            );
            currentMatchShortCodeTagIndex++;
            i++;
          }
        }
      }
    }
    return getSanitizedString(newCreatedString);
  } catch (err) {
    return getSanitizedString(newCreatedString);
  } finally {
    return getSanitizedString(newCreatedString);
  }
}

const getSanitizedString = str => {
  str = str.replace(/\[(.*?)\]/g, '');
  return sanitizeHtml(str, {
    allowedAttributes: false,
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'img',
      'iframe',
      'audio',
      'button',
    ]),
  });
};
