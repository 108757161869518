/* Built In imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useRef } from 'react';

/* External Imports */
import {
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  // MenuList,
  MenuItem,
  MenuList,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import filter from 'lodash/filter';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import { refineUrlDomain } from '@shi/components/Utility/utils';
import HeaderSt from '@shi/styleData/HeaderSt';

/**
 * Renders LangMenu component
 * @param {Obj} availableLocals
 * @param {Str} region
 * @param {Str} lang
 * @returns {ReactElement} LangMenu
 */
const LangMenu = ({ lang, region, availableLocals }) => {
  // console.log(lang, region);
  const { isOpen, onOpen, onClose } = useDisclosure();

  let filteredLocales = filter(availableLocals, function (item) {
    return item.locale.indexOf('_IN') > -1;
  });
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: onClose,
  });
  const router = useRouter();
  const path = router.asPath;
  return (
    filteredLocales?.length > 1 && (
      <Menu zIndex="101" isOpen={isOpen}>
        <MenuButton
          isActive={isOpen}
          as={Button}
          px={{ base: 2, lg: 4 }}
          bg="transparent"
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          borderWidth="0px"
          borderColor="transparent"
          _hover={{ bg: 'transparent', color: '#ECB11B' }}
          _expanded={{ bg: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
          fontFamily="FedraSansStd-book"
          fontSize="12px"
          _active={{ bg: 'transparent' }}
          {...HeaderSt[lang].deskMenu}
        >
          {config.lngListTrans[lang]}
          {isOpen ? (
            <Icon
              width="8px"
              height="6px"
              viewBox="0 0 6px 5px"
              fill="none"
              ml="5px"
            >
              <path
                d="M2.36608 0.823492C2.68632 0.407486 3.31368 0.407486 3.63392 0.823493L5.4726 3.21201C5.87756 3.73806 5.50255 4.5 4.83868 4.5H1.16132C0.49745 4.5 0.12244 3.73806 0.527398 3.21201L2.36608 0.823492Z"
                fill="#ECB11B"
              />
            </Icon>
          ) : (
            <Icon
              width="8px"
              height="6px"
              viewBox="0 0 6px 5px"
              fill="none"
              ml="5px"
            >
              <path
                d="M3.63392 4.17651C3.31368 4.59251 2.68632 4.59251 2.36608 4.17651L0.527398 1.788C0.12244 1.26194 0.497451 0.5 1.16132 0.5L4.83868 0.5C5.50255 0.5 5.87756 1.26194 5.4726 1.78799L3.63392 4.17651Z"
                fill="#ECB11B"
              />
            </Icon>
          )}
        </MenuButton>
        <MenuList
          zIndex="101"
          minW="1px"
          width="auto"
          ref={ref}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          {filteredLocales?.map((menu, index) => {
            const currMenu = config.locales.find(
              el => el.language_sf === menu.locale.replace('_IN', '')
            );
            const currLang = currMenu?.language_sf;
            const currTitle = config.lngListTrans[currLang];
            if (menu.locale.includes(lang)) {
              return null;
            }
            return (
              currTitle && (
                <NextLink
                  href={refineUrlDomain(
                    path.replace(`/${lang}/`, `/${currLang}/`),
                    region,
                    currLang
                  )}
                  passHref
                  legacyBehavior
                  key={nanoid()}
                >
                  <MenuItem
                    _active={{ color: '#CA4E2A' }}
                    {...HeaderSt[lang].deskSub}
                    _hover={{ textDecor: 'none', color: '#CA4E2A' }}
                    color="inherit"
                    px="0"
                    as="a"
                  >
                    <Link
                      px="12px"
                      {...HeaderSt[lang].deskSub}
                      _hover={{ textDecor: 'none', color: '#CA4E2A' }}
                      _focus={{ boxShadow: 'none' }}
                      color="inherit"
                    >
                      {/* {cuurMenu[language]} */}
                      {currTitle}
                    </Link>
                  </MenuItem>
                </NextLink>
              )
            );
          })}
        </MenuList>
      </Menu>
    )
  );
};
export default LangMenu;
