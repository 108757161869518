/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Footer from '@shi/components/footer/Footer';
import Header from '@shi/components/header/index';
import ScrollToTop from '@shi/components/Utility/ScrollToTop';

/* Services */


/**
 * Renders the Layout component.
 *
 * @param children.children
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */
export default function Layout({ children }) {
  const {
    headerMenuData,
    pageData,
    footerData,
    footerDetailData,
    region,
    lang,
    isPreview,
    headerVisible = true,
    page,
    secondMenuVisible = true,
    hideTopMenu = false,
  } = children.props;
  let pageConfig = '';

  const [currentUserRegion, setCurrentUserRegion] = useState(region);

  const [showHeader] = useState(headerVisible);

  const language = lang;

  return (
    <Box
      className={`lang-${language || 'en'}`}
    // pb={{ md: '0', base: isSticky ? '80px' : '' }}
    >
      {showHeader && pageConfig?.header != 'disable' ? (
        <Box pos="relative" float="left" w="100%">
          <Header
            headerMenuData={headerMenuData}
            availableLocals={pageData?.availableLocales}
            currentUserRegion={currentUserRegion}
            setCurrentUserRegion={val => setCurrentUserRegion(val)}
            region={region}
            language={language}
            page={page}
            secondMenuVisible={secondMenuVisible}
            hideTopMenu={hideTopMenu}
            languageAlsoin={pageData?.alsoin}
            pageUrl={pageData?.url}
          />
        </Box>
      ) : (
        ''
      )}
      {/* main content to render */}
      {children}
      {/* Footer section */}
      {(footerData || footerDetailData) && (
        <Box pos="relative" w="100%">
          <Footer
            region={region}
            language={language}
            footerData={footerData}
            pageConfig={pageConfig}
            page={page}
          />
        </Box>
      )}
      {isPreview && <WithPreview isPreview={isPreview} />}
      <ScrollToTop />
    </Box>
  );
}
