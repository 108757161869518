/* Built In Imports */
import Image from 'next/image';
import { useEffect, useState } from 'react';

/* External Imports */
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Hide,
  Icon,
  Link,
  List,
  ListItem,
  Show,
  Text
} from '@chakra-ui/react';
import filter from 'lodash/filter';
import { nanoid } from 'nanoid';

/* Internal Imports */
import config from '@config';
import LangMenu from '@shi/components/header/LangMenu';
import NavLink from '@shi/components/header/NavLink';
import { refineUrlDomain } from '@shi/components/Utility/utils';
import HeaderSt from '@shi/styleData/HeaderSt';

/* Styles */
/* Services */

const Logo = ({ lang, region, data }) => {
  return (
    <Box py={{ base: '15px', md: '22px' }} height="100%" pr="20px">
      {/* <NextLink
        href={refineUrlDomain(data.url, region, lang)}
        passHref
        legacyBehavior
      > */}
      <Link
        href={refineUrlDomain(data.url, region, lang)}
        _hover={{ textDecor: 'none', color: '#CA4E2A' }}
        focus={{ boxShadow: 'none !important' }}
        boxShadow="none !important"
      >
        <Show above="lg">
          <Image
            src={`${config.imgPath}/d/46272/1647884636-shivanga.svg`}
            width={146}
            height={54}
            alt="logo"
            title="logo"
            priority
          />
        </Show>
        <Hide above="lg">
          <Image
            src={`${config.imgPath}/d/46272/1647884636-shivanga.svg`}
            width={106}
            height={32}
            alt="logo"
            title="logo"
            priority
          />
        </Hide>
      </Link>
      {/* </NextLink> */}
    </Box>
  );
};

/**
 * Renders Header Component
 * @param {Obj} headerMenuData
 * @param {Obj} availableLocals
 * @param {Str} region
 * @param {Str} language
 * @returns {ReactElement} Header
 */
const Header = ({ headerMenuData, region, language, availableLocals }) => {
  const lang = language;
  const headerData = headerMenuData?.allIsoMainMenus;
  const donate = headerData?.find(el => el?.key === 'donate');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  let filteredLocales = filter(availableLocals, function (item) {
    return item.locale.indexOf('_IN') > -1;
  });

  return headerData ? (
    <Box w="100%" bg="#000">
      <Box as="nav" maxW={1440} mx="auto">
        <Flex w="100%" justifyContent="space-between" px="5%">
          <Box
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Logo lang={'en'} region={region} data={headerData?.[0]} />
          </Box>
          {/* Nav Links */}
          <Box className="nav-links-container">
            <Flex>
              <Show above="md">
                <Flex py={{ base: '15px', md: '22px' }}>
                  <List display="flex" alignItems="center">
                    {headerData.map(el => {
                      if (el.key === 'donate') return null;
                      else if (el.key === 'home') return null;
                      else if (el.submenuItems.length) {
                        return (
                          <ListItem
                            key={nanoid()}
                            mr={{ base: '15px', lg: '25px' }}
                            ml="0"
                          >
                            <NavLink
                              subMenu={el?.submenuItems}
                              el={el}
                              region={region}
                              lang={'en'}
                            />
                          </ListItem>
                        );
                      } else {
                        return (
                          <ListItem key={nanoid()}>
                            <Link
                              href={refineUrlDomain(el?.url, region, lang)}
                              {...HeaderSt[lang].deskMenu}
                              _hover={{ textDecor: 'none', color: '#CA4E2A' }}
                              color="#fff"
                            >
                              {el.key}
                            </Link>
                          </ListItem>
                        );
                      }
                    })}
                    <ListItem>
                      <Icon
                        width="2px"
                        mr="25px"
                        height="33px"
                        viewBox="0 0 2px 33px"
                        fill="none"
                      >
                        <path opacity="0.4" d="M1 0V32.5" stroke="white" />
                      </Icon>
                    </ListItem>
                  </List>
                </Flex>
              </Show>
              {filteredLocales?.length > 1 &&
                availableLocals?.length > 1 && region === 'in' && (
                  <Box
                    py={{ base: '15px', md: '22px' }}
                    mr={{ base: '0px', md: '45px' }}
                  >
                    <LangMenu
                      lang={lang}
                      region={region}
                      availableLocals={availableLocals}
                    />
                  </Box>
                )}
              <Hide below="md">
                <Box py={{ base: '15px', md: '28px' }}>
                  {/* <NextLink
                    passHref
                    legacyBehavior
                  > */}
                  <Link
                    href={refineUrlDomain(donate?.url, region, 'en')}
                    // as={Button}
                    variant="primary"
                    px="34px"
                    pt="9px"
                    pb="10px"
                    border="1px solid #FFF"
                    bg="transparent"
                    rounded="3px"
                    color="#fff"
                    _focus={{
                      bg: '#E19502',
                      color: '#fff',
                      border: '1px solid transparent',
                    }}
                    height="40px"
                    _hover={{
                      bg: '#E19502',
                      color: '#fff',
                      border: '1px solid transparent',
                    }}
                  >
                    {donate.title}
                  </Link>
                  {/* </NextLink> */}
                </Box>
              </Hide>

              <Show below="md">
                <Box py={{ base: '15px', md: '22px' }}>
                  {!isOpen && (
                    <HamburgerIcon
                      colorScheme="transparent"
                      border="0px solid"
                      aria-label="opens drawer"
                      px="0"
                      width={"28px"}
                      height="100%"
                      color="#FFFFFF"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                      _hover={{
                        boxShadow: 'none',
                        color: '#E19502',
                      }}
                      _focus={{
                        boxShadow: 'none',
                      }}
                    />
                  )}
                  {isOpen && (
                    <CloseIcon
                      colorScheme="transparent"
                      color="#FFFFFF"
                      cursor="pointer"
                      width={"20px"}
                      height="100%"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    />
                  )}
                </Box>
                {isOpen && (
                  <Box
                    height="calc(100vh - 70px)"
                    position="fixed"
                    zIndex="100"
                    left="0"
                    width="100%"
                    right="0"
                    top="60px"
                    bg="#00052F"
                    px="31px"
                    py="16px"
                  >
                    <Box width="100%" maxHeight="100%" overflow="auto">
                      <Flex direction="column">
                        <List>
                          {headerData.map(el => {
                            if (el.title === 'Home') return null;
                            else if (el.submenuItems.length) {
                              return (
                                <ListItem key={nanoid()}>
                                  <Text
                                    mb="15px"
                                    {...HeaderSt[lang].mobileMenu}
                                  >
                                    {el.title}
                                  </Text>
                                  <List>
                                    {el?.submenuItems.map(menu => {
                                      return (
                                        <ListItem>
                                          {/* <NextLink
                                            
                                            passHref
                                            legacyBehavior
                                          > */}
                                          <Link
                                            href={refineUrlDomain(
                                              menu.url,
                                              region,
                                              lang
                                            )}
                                            onClick={() => setIsOpen(false)}
                                            {...HeaderSt[lang].mobileSubmenu}
                                            _hover={{
                                              textDecor: 'none',
                                              color: '#CA4E2A',
                                            }}
                                          >
                                            <Text mb="6px">{menu.title}</Text>
                                          </Link>
                                          {/* </NextLink> */}
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </ListItem>
                              );
                            } else {
                              return (
                                <ListItem
                                  key={nanoid()}
                                  _hover={{
                                    textDecor: 'none',
                                    color: '#CA4E2A',
                                  }}
                                  {...HeaderSt[lang].mobileSMenu}
                                >
                                  {/* <NextLink
                                    
                                    passHref
                                    legacyBehavior
                                  > */}
                                  <Link
                                    href={refineUrlDomain(el.url, region, 'en')}
                                    onClick={() => {
                                      setIsOpen(false);
                                    }}
                                  >
                                    {el.title}
                                  </Link>
                                  {/* </NextLink> */}
                                </ListItem>
                              );
                            }
                          })}
                        </List>
                      </Flex>
                    </Box>
                  </Box>
                )}
              </Show>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Header;
