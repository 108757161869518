/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Image } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders ScrollToTop
 * @returns {ReactElement} ScrollToTop
 */
export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // scroll-to-top classes: fixed, bottom:0, right:0
  return (
    <>
      <Box>
        {isVisible && (
          <Box
            pos="fixed"
            w="50px"
            h="50px"
            right="30px"
            bottom="65px"
            borderRadius="25px"
            bgColor="rgb(0 0 0 / 50%)"
            cursor="pointer"
            zIndex="10"
            onClick={scrollToTop}
          >
            <Image
              w="30px"
              transform="rotate(-180deg)"
              margin="7px auto auto"
              display="block !important"
              src={`${config.staticPath}/d/46272/1684434191-chevron-down-white.svg`}
              alt="Go to top"
              title="Go to top"
            ></Image>
          </Box>
        )}
      </Box>
    </>
  );
}
