/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 *
 * @param url
 */
function youTubeParser(url) {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

/**
 * Renders the html Parser Component
 *
 * @param {string} language - Language of the page
 * @returns {ReactElement} html Parser component
 */
const htmlParser = ({
  type,
  tagWithoutBracket,
  text,
  attributesArray,
  language,
}) => {
  // const isDesktop = useMediaQuery({ maxWidth: 1224 });
  // const isLaptop = useMediaQuery({ maxWidth: 1024, minWidth: 1224 });
  // const isMedium = useMediaQuery({ maxWidth: 768, minWidth: 1024 });
  // const isTab = useMediaQuery({ maxWidth: 480, minWidth: 768 });
  // const isMobile = useMediaQuery({ maxWidth: 300, minWidth: 480 });
  // let imgHeight = 0;
  // if (isDesktop) {
  //   imgHeight = 201;
  // } else if (isLaptop) {
  //   imgHeight = 202;
  // } else if (isMedium) {
  //   imgHeight = 203;
  // } else if (isTab) {
  //   imgHeight = 204;
  // } else {
  //   imgHeight = 205;
  // }

  let htmlCode = '';
  const router = useRouter();
  switch (tagWithoutBracket) {
    case 'readonly':
      htmlCode = `<div class="'readmore'">                
                <div class="readmore-full complete" style="display:none;"> ${text} </div>
                <div class="col-xs-12 text-center padding-top-n-btm-3">
                <button class="btn btn-default btn-lg isha-yoga-content-read-btn read-more-btn rm-show">Read More</button>
                <button class="btn btn-default btn-lg isha-yoga-content-read-btn rm-hide read-more-btn" style="display:none;">Read Less</button>
                </div>
            </div>`;
      break;
    case 'pullquote':
      htmlCode = `<div className="isha-article-quote-bg">
                  <div className="article-right-blue">
                  <div className="isha-article-quote"> ${text} </div></div></div>`;
      break;
    case 'quote':
      htmlCode = `<span class="quote"> ${text} </span>`;
      break;
    case 'singleDivider':
      // htmlCode = '';
      break;
    case 'divider':
      htmlCode = '<div class="divider"></div>';
      break;
    case 'sadhguruimage':
      htmlCode = `<div class="sadhguruImage"></div>
                  ${text}`;
      break;
    case 'dropcap':
      htmlCode = `<span class="dropcap"><span class="text"> ${text} </span></span>`;
      break;
    case 'sadhgurusignature':
      htmlCode = `<div className="sadhguru-signature ${type === 'poem' ? 'white center' : ''
        } left"> ${attributesArray['salutation'] || 'Love & Grace'}</div>`;
      break;
    case 'separator':
      htmlCode = '<div class="separator" style="border: 1px solid #ccc"></div>';
      break;
    case 'caption':
      htmlCode = `<div><div class="isha-article-caption margin-top-n-btm caption-text"> ${text} </div></div>`;
      break;
    case 'poem':
      htmlCode =
        type === 'poem'
          ? `<div className="poem-poemBlock">
              <div className="heading">${attributesArray['title'] || ''}</div>
              <div className="poem">
                ${text}
              </div>
            </div>`
          : `<div className="poemBlock">
          <div className="heading">${attributesArray['title'] || ''}</div>
          <div className="dividerimg">
          <img className="articleImage lazy-image" data-src=${config.imgPath
          }/d/46272/1663645833-flower-divider-horizontal-new.svg />
          </div>
          <div>
          <div className="poem">
            ${text}
          </div>
          </div>
        </div>`;
      break;
    case 'toggle_item':
      htmlCode = `
          <li class="toggle-item">
            <h2 class='toggle-box-head title-color gdl-title'>
              <span class="toggle-box-head-image ${attributesArray['active'] || ''
        }"> 
                ${attributesArray['title']}
              </span>
            </h2>
            <div class='toggle-box-content ${attributesArray['active'] || ''} '>
              ${text}
            </div>
          </li>`;
      break;
    case 'space':
      htmlCode = `<div style="height: ${attributesArray['height']} px">&nbsp;Space added here </div>`;
      break;
    case 'question':
      htmlCode = `<div class="isha-answer question"><span class="isha-question">Question: </span> ${text} </div>`;
      break;
    case 'ps':
      htmlCode = `<div class="isha-ps post-script"> ${text} </div>`;
      break;
    // case 'powerpress':
    //   htmlCode = `<div class='isha-audio-player short-code'>
    //               <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding audioplayer" id="audioplayer">
    //               <audio id="audio2">
    //                 <source src="${attributesArray['url']}" type="audio/mpeg">
    //                 ${attributesArray['url']}
    //               </audio>
    //               <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3 no-padding tcenter">
    //               <button class="pButton play"></button>
    //               </div>
    //               <div class="col-lg-10 col-md-10 col-sm-8 col-xs-6 no-padding timeline">
    //               <div class="playhead"></div>
    //               </div>
    //               <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3 no-padding tcenter mar-top30">
    //               <span class="musDuration">11:37</span>
    //               </div>
    //               </div>
    //               </div>`;
    //   break;
    case 'message_box':
      htmlCode = `<div class="message_box"><div> ${attributesArray['title']} </div> ${text} </div>`;
      break;
    case 'list':
      htmlCode = `<div class="margin-bottom-2 shortcode-list"><strong> ${attributesArray['title'] || ''
        } </strong> ${text} </div>`;
      break;
    case 'intro_text':
      htmlCode = `<span class="introtext intro_text"> ${text} </span>`;
      break;
    case 'info_text':
      htmlCode = `<div class="info_text"> ${text} </div>`;
      break;
    case 'image':
      htmlCode = `<div class="image-shortcode img no-margin ${attributesArray['align']
        }">
          <div class="img-inner"><img data-src="${attributesArray['src']
        }" alt="${attributesArray['alt'] ? attributesArray['alt'] : ''
        }" align="${attributesArray['align']
        }" class="img-responsive img lazy-image"></div>
          <div class="isha-article-caption margin-top-n-btm"> ${text || ''
        } </div>
        </div>`;
      break;
    case 'editorsnote':
      htmlCode = `<div className="editor-desc"><div class="editor-inner"><strong> Editor's Note: </strong> ${text} </div></div>`;
      break;
    case 'button':
      htmlCode = `<a href="${attributesArray['src']}" ><button class="btn-isha-1"> ${text} </button></a>`;
      break;
    case 'envurl':
      if (config.ENV_NAME === 'production') {
        htmlCode = `${attributesArray['prod']}`;
      } else {
        htmlCode = `${attributesArray['uat']}`;
      }
      break;
    case 'twitter':
      htmlCode = `<div class="twitter-iframe"><blockquote class="twitter-tweet">\r\n
        <p dir="ltr" lang="und"></p>\r\n<a href=${text}></a>
        </blockquote>\r\n</div>`;
      break;
    case 'audio':
    case 'powerpress':
      const isPathAudio = router?.asPath?.includes('/audio/');
      if (isPathAudio) {
        // htmlCode = '';
      } else {
        htmlCode = `<div class="audio-player powerpress" style="margin: 0">
			<div class="timeline">
			  <div class="progress"></div>
			</div>
			<div class="controls">
			  <div class="play-container">
				<div class="toggle-play play"></div>
			  </div>
			  <div class="time">
				<div class="current">0:00</div>
				<div class="">/</div>
				<div class="length"></div>
			  </div>
			  <div class="volume-container">
				<div class="volume-button">
				  <div class="volume icono-volumeMedium"></div>
				</div>

				<div class="volume-slider">
				  <div class="volume-percentage"></div>
				</div>
			  </div>
			</div>
		  </div>`;
      }

      // htmlCode = `<div class='isha-audio-player short-code'>
      //     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding audioplayer" id="audioplayer">
      //             <audio id="audio2" controls>
      //               <source src="${
      //                 attributesArray['src'] || attributesArray['url']
      //               }" type="audio/mp3">
      //               Your browser does not support the audio element.
      //             </audio>
      //             <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3 no-padding tcenter">
      //               <button class="pButton play"></button>
      //             </div>
      //             <div class="col-lg-10 col-md-10 col-sm-8 col-xs-6 no-padding timeline">
      //               <div class="playhead"></div>
      //             </div>
      //             <div class="col-lg-1 col-md-1 col-sm-2 col-xs-3 no-padding tcenter mar-top30">
      //               <span class="musDuration"></span>
      //             </div>
      //         </div>
      //     </div>`;
      break;
    case 'answer':
      htmlCode = `<div class="answer"><div>${attributesArray['title']}</div> ${text} </div>`;
      break;
    case 'youtube':
      // const width = tagWithoutBracket[1].match(/\d+/)[0];
      if (text && text.length) {
        const { height } = attributesArray;
        const re =
          /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
        const url = re.exec(text);
        if (url) {
          const videoId = youTubeParser(url['0']);
          htmlCode = `<div style="position:relative; width: 106%; margin-left: -3.5%; box-shadow: 0 0 45px 0 rgb(0 0 0 / 50%);">
              <iframe 
                loading="lazy"
                width="100%"
                height=${height || '405px'}
                allowFullScreen
                src=https://www.youtube.com/embed/${videoId}?responsive=1&amp;autoplay=0&amp;start=0&amp;rel=0>
              </iframe>
            </div>`;
        }
      }
      break;
    case 'photocredit':
      htmlCode = `<div class="isha-image-credit imagecredit"<a> ${text} </a></div>`;
      break;
    case 'raw':
      htmlCode = `<div> ${text} </div>`;
      break;
    default:
      htmlCode = '<div></div>';
  }
  return htmlCode;
};

export default htmlParser;
