/* Built In Imports */
import type { AppProps } from 'next/app';
import { useEffect, useRef } from 'react';

/* External Imports */
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

/* Internal Imports */
/* Components */
import BuildTimeComment from '@components/Utility/BuildTimeComment';
import Layout from '@shi/components/Layout/Layout';
import AuthContextProvider from '@store/auth-context';
/* Mixpanel */
import { mixPanel } from '@utils/Mixpanel';

/* Services */
import '../styles/globals-shivanga.css';
import { shivangaTheme } from '../styles/theme/index';

export default function MyApp({ Component, pageProps }: AppProps) {
  const firstUpdate = useRef(true);

  const tagManagerArgs = {
    gtmId: 'GTM-5G2NJ8P',
  };

  useEffect(() => {

  /* Start Mixpanel  */
   const Mixpanel = mixPanel(
    window?.location.pathname
  );
  if (Object.keys(Mixpanel)?.length > 0) {
    const mixPanelPageViewObj = {
      page_name: window?.document.title,
    };
    // Track shivanga page views
    if (window?.location.pathname.startsWith('/shivanga')) {
      Mixpanel.track('shivanga_pageview', mixPanelPageViewObj);
    }
  }
    /* End Mixpanel  */
    setTimeout(function () {
      if (firstUpdate.current) {
        if (process.browser) {
          TagManager.initialize(tagManagerArgs);
        }
        firstUpdate.current = false;
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          url: window.location.href,
          title: window.document.title,
        });
      }
    }, 1500);
  }, [pageProps]);

  return (
    <AuthContextProvider>
      <ChakraProvider resetCSS theme={shivangaTheme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ChakraProvider>
      <BuildTimeComment />
    </AuthContextProvider>
  );
}
