import config from '@config';
import merge from 'lodash/merge';

const fontFamily = 'FedraSansStd-medium';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    h1: {
      fontSize: { lg: '44px', md: '40px', base: '28px' },
      fontFamily: 'FedraSansStd-A-medium',
    },
    footerLink: {
      color: '#A6A6A6',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '29.792px',
      textDecorationLine: 'underline',
    },
    footerTitle: {
      color: '#FFF',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'normal',
      // fontWeight: '600',
      lineHeight: '26.068px',
    },
    footerDes: {
      color: '#A6A6A6',
      fontFamily: isIndian ? config.isoLangFont[lang] : 'FedraSansStd-book',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '29.792px',
    },
    copyText: {
      color: '#565656',
      fontFeatureSettings: "'case' on",
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      fontSize: '13.882px',
      fontStyle: 'normal',
      // fontWeight: '600',
      lineHeight: '22.025px',
    },
    scrollTop: {
      color: '#FFF',
      textAlign: 'center',
      fontFamily: isIndian ? config.isoLangFont[lang] : fontFamily,
      fontSize: '13.189px',
      fontStyle: 'normal',
      // fontWeight: '600',
      lineHeight: '22.025px',
    },
  };

  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const FooterSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({
    lang: 'hi',
  }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
  }),
  id: commonLangStyle({ lang: 'id' }),
};
export default FooterSt;
