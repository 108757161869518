/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Link } from '@chakra-ui/react';
import parser from 'html-react-parser';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@shi/components/Utility/utils';

/* Services */

/**
 * Renders parsed string for structured text ShortCode.
 *
 * @param {string} str - HTML string.
 * @param {string} className - Class Name
 * @param {string} region - Region of the page
 * @param {string} textAlign - Alignment of the page
 * @returns {ReactElement} Structured Text Parser Code component
 */
const StructuredTextParser = ({
  str,
  mt,
  ml,
  className,
  textAlign,
  region,
  lang,
  maxW,
  fontSize,
  style,
  ...props
}) => {
  const allTags = ['readmore'];
  const [readMore, setReadMore] = useState(true);
  const structured = useRef();
  // const history = useHistory();
  if (textAlign && textAlign === 'center-align') {
    textAlign = 'center';
  }

  let innerText = '';
  let initialString = '';
  let startTagIndex = -1;

  // <a([^>]* )href=\"([^\"]+)\"
  const re = new RegExp('href="([^"]+)"', 'g');
  let strMatched = str && str.match(re);
  if (strMatched && strMatched.length) {
    forEach(strMatched, function (matched) {
      matched = matched.replace('href=', '').replace(/"/g, '');
      str = str.replace(
        `"${matched}"`,
        `"${refineUrlDomain(matched, region, lang)}"`
      );
    });
  }

  forEach(allTags, function (tag) {
    let matched = str && str.match(/\[(.*?)\]/g);
    if (matched && matched.length && matched[0].indexOf(tag) > -1) {
      startTagIndex = str.indexOf(matched[0]);
    }

    initialString = str?.substring(0, startTagIndex) || str;
    if (startTagIndex && startTagIndex > -1) {
      let endTagStartIndex = str.indexOf(`[/${tag}]`);
      let endTagEndIndex = endTagStartIndex + tag.length + 3;
      let tagTextString = str?.substring(startTagIndex, endTagEndIndex);
      innerText = tagTextString
        .replace(`[${tag}]`, '')
        .replace(`[/${tag}]`, '')
        .replace(matched[0], '');
    }
  });

  useEffect(
    () => () => {
      setReadMore(true);
    },
    [str]
  );

  const sId = nanoid();
  return (
    <Box ref={structured} id={sId}>
      <Box
        className={
          className || props?.theme === 'light'
            ? 'structured-content-light'
            : 'structured-content-dark'
        }
        m={{ base: '0 auto' }}
        mt={{ base: mt || '15px', md: mt || '15px', lg: mt || '15px' }}
        ml={{ base: ml || '0', md: ml || '0', lg: ml || '0' }}
        maxW={{
          base: '100%',
          md: maxW || '100%',
        }}
        textAlign={textAlign || 'left'}
        fontSize={{
          base: `${fontSize} !important` || '1rem',
          md: `${fontSize} !important` || '1rem',
          lg: `${fontSize} !important` || '1rem',
        }}
        color={
          textAlign === 'center' || textAlign === 'center-align'
            ? '#FFF7E9'
            : '#C1C3DD'
        }
        px={{ base: 2, md: '' }}
        {...props}
      >
        {initialString && parser(initialString)}

        {startTagIndex > -1 && (
          <Box
            as="p"
            maxW="100% !important"
            m={{ base: '0 auto' }}
            mt={{
              base: mt || style === 'donateStyle' ? '0' : '0',
              md: mt || style === 'donateStyle' ? '0px' : '0',
            }}
            // pb="30px"
            textAlign={textAlign || 'left'}
          >
            <Collapse in={!readMore} animateOpacity duration={20000}>
              {/* <Box
              pb="30px"
              textAlign={textAlign || 'left'}
            > */}
              {parser(innerText)}
              {/* </Box> */}
            </Collapse>

            <Flex
              alignItems="center"
              justifyContent={style === 'donateStyle' ? 'start' : 'center'}
              textAlign={'center'}
              position="relative"
              mt="15px"
            >
              <Link
                // display="flex"
                display={style === 'donateStyle' ? 'flex' : 'inline-block'}
                alignItems={'center'}
                mb="20px"
                fontFamily="FedraSansStd-medium"
                bgColor="transparent"
                border={style == 'donateStyle' ? 'none' : 'none'}
                color={style === 'donateStyle' ? '#CA4E2A' : '#CA4E2A'}
                fontSize="18px !important"
                padding={style === 'donateStyle' ? '0px' : '10px 35px'}
                textDecoration="none !important"
                transition="none !important"
                _hover={{
                  textDecoration: 'none',
                  bgColor: style === 'donateStyle' ? 'none' : 'none',
                  color: style === 'donateStyle' ? '#983C21' : '#983C21',
                }}
                textAlign="center"
                width={style === 'donateStyle' ? 'fit' : '220px'}
                onClick={() => setReadMore(!readMore ? true : false)}
                m={style === 'donateStyle' ? '0' : '0 auto'}
                _focus={{ boxShadow: 'none' }}
                mt={
                  style === 'donateStyle' && readMore
                    ? { base: '10px', sm: '10px', md: '-10px' }
                    : '0px'
                }
              >
                <>{readMore ? 'More' : 'Less'} Details</>
                {readMore ? (
                  <ChevronDownIcon w={7} h={6} />
                ) : (
                  <ChevronUpIcon w={7} h={6} />
                )}
              </Link>
            </Flex>
          </Box>
        )}
      </Box>

      {fontSize && (
        <style>
          {`
              #${sId} .structured-content-dark p {
                font-size: ${fontSize} !important;
              }
          `}
        </style>
      )}
    </Box>
  );
};

export default StructuredTextParser;
