/* Built In Imports */
import { useRef } from 'react';

import {
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  // MenuList,
  MenuItem,
  MenuList,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
import { refineUrlDomain } from '@shi/components/Utility/utils';
import HeaderSt from '@shi/styleData/HeaderSt';


/**
 *
 * @param {Obj} el
 * @param {Str} lang
 * @param {Str} region
 * @returns
 */

const NavLink = ({ el, lang, region }) => {
  // console.log(el.submenuItems, 'submenu');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: onClose,
  });
  return (
    <Menu isOpen={isOpen}>
      {/* {({ isOpen }) => (
        <> */}
      <MenuButton
        isActive={isOpen}
        as={Button}
        px={{ base: 2, lg: 4 }}
        bg="transparent"
        py={2}
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="0px"
        borderColor="transparent"
        _hover={{ bg: 'transparent', color: '#ECB11B' }}
        _expanded={{ bg: 'transparent' }}
        _focus={{ boxShadow: 'none' }}
        fontFamily="FedraSansStd-book"
        fontSize="16px"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        _active={{ bg: 'transparent' }}
        color="#fff"
        {...HeaderSt[lang].deskMenu}
      >
        {el?.title}
        {isOpen ? (
          <Icon
            width="6px"
            height="5px"
            viewBox="0 0 6px 5px"
            fill="none"
            ml="5px"
          >
            <path
              d="M2.36608 0.823492C2.68632 0.407486 3.31368 0.407486 3.63392 0.823493L5.4726 3.21201C5.87756 3.73806 5.50255 4.5 4.83868 4.5H1.16132C0.49745 4.5 0.12244 3.73806 0.527398 3.21201L2.36608 0.823492Z"
              fill="#ECB11B"
            />
          </Icon>
        ) : (
          <Icon
            width="6px"
            height="5px"
            viewBox="0 0 6px 5px"
            fill="none"
            ml="5px"
          >
            <path
              d="M3.63392 4.17651C3.31368 4.59251 2.68632 4.59251 2.36608 4.17651L0.527398 1.788C0.12244 1.26194 0.497451 0.5 1.16132 0.5L4.83868 0.5C5.50255 0.5 5.87756 1.26194 5.4726 1.78799L3.63392 4.17651Z"
              fill="#ECB11B"
            />
          </Icon>
        )}
      </MenuButton>
      <MenuList
        minW="1px"
        w="auto"
        zIndex={19}
        onMouseEnter={onOpen}
        ref={ref}
        onMouseLeave={onClose}
      >
        {el?.submenuItems?.map(menu => {
          return (
            <Link
              key={nanoid()}
              href={refineUrlDomain(menu.url, region, lang)}
              {...HeaderSt[lang].deskSub}
              _hover={{ textDecor: 'none', color: '#CA4E2A' }}
              color="inherit"
            >
              <MenuItem
                _active={{ color: '#CA4E2A' }}
                {...HeaderSt[lang].deskSub}
                _hover={{ textDecor: 'none', color: '#CA4E2A' }}
                color="inherit"
                as="a"
              >
                {menu.title}
              </MenuItem>
            </Link>
          );
        })}
      </MenuList>
      {/* </>
      )} */}
    </Menu>
  );
};
export default NavLink;
