/* Built In Imports */
/* External Imports */
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import config from '@config';
import { ShortCodeProcess } from '@shi/components/Utility/ShortCode';

/* Services */

/**
 * Renders the should Refresh Component
 *
 * @param {string} url - Link
 * @returns {ReactElement} - should Refresh Component
 */

const domainList = [
  '//prs.innerengineering.com',
  '//prs.isha.in',
  '//sadhana.sadhguru.org',
  'mokshatwo.sushumna.isha.in',
  'goyip.netlify.app',
  'ishangam.isha.in',
  'msrsp.sadhguru.org',
  'ishaoutreach.org',
  'iycblr.sadhguru.org',
  'sp.isha.in',
  'uat-facade-sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'ishaprs.sushumna.isha.in',
  'online.sadhguru.org',
  'online2.sadhguru.org',
  'registration.innerengineering.com',
  'qa-prs8.innerengineering.com',
  'lingabhairavi.org',
  'beta.lingabhairavi.org',
  'hys.sushumna.isha.in',
  'hys.isha.in',
  'isha.co',
  'preprod-online.sadhguru.org',
];

export const numFormatter = num => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const getCookie = name => {
  if (typeof window !== 'undefined') {
    const value = `; ${document.cookie}`;
    const parts = value?.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  }
};

export const handleUtmParams = url => {
  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  let newUrl = '';

  let isUrlAvailableForUtmParam = filter(domainList, function (domain) {
    return url.indexOf(domain) > -1;
  });

  if (isUrlAvailableForUtmParam.length && url.indexOf('?') < 0) {
    let nUrl = new URL(url);
    let search_params = new URLSearchParams(nUrl.search);
    // let search_params = nUrl.searchParams;

    forEach(cookieParams, function (cookie) {
      let cookieVal = getCookie(cookie);
      if (cookieVal) {
        search_params.set(cookie, cookieVal);
      }
    });
    // change the search property of the main url
    nUrl.search = search_params.toString();

    // the new url string
    newUrl = nUrl.toString();
  } else {
    newUrl = url;
  }
  // consoleLog('finalUrl', newUrl);
  return newUrl;
};

export const isOpenInNewTab = url => {
  let isUrlAvailableForUtmParam = [];
  if (url?.startsWith('http') && url?.indexOf(config.cdnPath) < 0) {
    isUrlAvailableForUtmParam = [url];
  } else {
    isUrlAvailableForUtmParam = filter(domainList, function (domain) {
      return url.indexOf(domain) > -1;
    });
  }
  return isUrlAvailableForUtmParam?.length > 0;
};

export const refineUrlDomain = (url, region, lang, page) => {
  let paramUrl =
    url?.indexOf('[envurl') > -1 ? ShortCodeProcess(url.toString()) : url;

  /**
   *
   */
  function checkRegionDomain() {
    const regions = ['in', 'global', 'au', 'ca', 'my', 'sg', 'uk', 'us'];
    const languages = ['en', 'hi', 'ta', 'te', 'kn', 'ml', 'mr'];

    let isMatched = false;
    forEach(regions, function (reg) {
      forEach(languages, function (language) {
        if (!isMatched) {
          isMatched =
            paramUrl?.toLowerCase().indexOf(`/${reg}/${language}/`) === 0;
        }
      });
    });
    if (!isMatched) {
      // checking if it contains /en/ structure\
      if (paramUrl?.toLowerCase().indexOf('/en/') > -1) {
        isMatched = true;
      }
    }
    return isMatched;
  }
  let finalUrl = '';

  if (!paramUrl) {
    finalUrl = '#';
  } else if (
    (paramUrl && paramUrl[0] === '#') ||
    paramUrl?.toLowerCase().indexOf('https://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('http://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('mailto:') >= 0
  ) {
    finalUrl = paramUrl;
  } else if (checkRegionDomain()) {
    finalUrl = `${paramUrl[0] === '/' ? '' : '/'}${paramUrl}`;
  } else if (paramUrl?.indexOf('/shivanga') > -1) {
    finalUrl = paramUrl;
  } else {
    finalUrl = `/shivanga/${region}/${lang}${paramUrl[0] === '/' ? '' : '/'
      }${paramUrl}`;
  }
  finalUrl = `${finalUrl}${finalUrl.endsWith('/') ||
    finalUrl.indexOf('#') > -1 ||
    finalUrl.endsWith('.pdf') ||
    finalUrl.endsWith('.jpg') ||
    finalUrl.startsWith('http')
    ? ''
    : '/'
    }`;
  // if (finalUrl.indexOf('#sadhana') > -1) {
  //  console.log('finalUrl', finalUrl, paramUrl);
  // }
  finalUrl = handleUtmParams(finalUrl);

  return finalUrl;
};

export const getEmbedUrl = url => {
  let retUrl = '';
  if (!url) {
    return retUrl;
  }
  if (url.indexOf('/watch') > -1) {
    retUrl = url.split('=')[1];
  } else if (url.indexOf('/embed/') > -1 || url.indexOf('/youtu.be/') > -1) {
    let splitArr = url.split('/');
    let splittedUrl = splitArr[splitArr.length - 1];
    retUrl =
      splittedUrl.split('?').length > 1
        ? splittedUrl.split('?')[0]
        : splittedUrl;
  } else {
    retUrl = url;
  }
  return retUrl;
};

const toDataURL = async url => {
  const blob = await fetch(url).then(res => res.blob());
  return URL.createObjectURL(blob);
};

export const downloadUrl = async (url, name) => {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const canRenderContent = (
  requestRegion,
  sectionRegion,
  sectionContent
) => {
  let renderContent = true;
  if (
    (requestRegion &&
      sectionRegion &&
      sectionRegion !== 'default' &&
      requestRegion !== sectionRegion &&
      (requestRegion === 'in' || sectionRegion !== 'global')) ||
    !sectionContent ||
    sectionContent?.length === 0
  ) {
    renderContent = false;
  }
  return renderContent;
};
